import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import axios from 'axios';

import { Box, Typography, Button } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useConfig, fhirExtensionUrls } from '@worklist-2/core/src';

import { Collapsible } from '../../../../Cases/casePopOverStyles';
import { FormSwitch, FormSelect, FormInput } from './Form/index';

import { getDeviceType } from './utils';

import createStyles from './styles';

const validationSchema = yup.object().shape({
	active: yup.boolean(),
	deviceType: yup.string().required('Device Type Name is required'),
	deviceName: yup.string().required('Device name is required'),
	AETitle: yup.string().required('AE Title is required'),
	port: yup.string().required('Port is required'),
	role: yup.string(),
	receivingManagingOrganizationAs: yup.string(),
	receivingImagingOrganizationAs: yup.string(),
	receiveAssigningAuthorityAs: yup.string(),
	receiveStudyAs: yup.string(),
	hideRejectedInstances: yup.boolean(),
	sendToDicomWeb: yup.boolean(),
	queryRetrieveFromDicomWeb: yup.boolean(),
	authenticationType: yup.string(),
	searchURL: yup.string(),
	retrieveURL: yup.string(),
	storeURL: yup.string(),
	authorizationURL: yup.string(),
	clientID: yup.string(),
	clientSecret: yup.string(),
	scope: yup.string(),
	tokenURI: yup.string(),
	claimIssuer: yup.string(),
	JWTSigningAlgorithm: yup.string(),
	privateKey: yup.string(),
	name: yup.string(),
	password: yup.string(),
});

const AddEditDeviceTab = forwardRef(
	(
		{ accountData, orgData, deviceOpen, setSaveDeviceActive, setDeviceOpen, newOmegaAIName, newDicomWEBName },
		ref
	) => {
		const [generalInfoWrapped, setGeneralInfoWrapped] = useState(false);
		const [dicomWebFeaturesWrapped, setDicomWebFeaturesWrapped] = useState(false);
		const [authenticationTypeWrapped, setAuthenticationTypeWrapped] = useState(false);
		const [roles, setRoles] = useState([]);
		const [studyAs, setStudyAs] = useState([]);
		const __config = useConfig();
		const classes = createStyles();

		const orgDataId = deviceOpen?.orgData?.id ? deviceOpen.orgData.id : orgData.id;
		const orgDataName = deviceOpen?.orgData?.display ? deviceOpen.orgData.display : orgData.name;
		const nameValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.username)
				?.valueString || '';
		const passwordValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.dicompassword)
				?.valueString || '';
		const claimIssuerValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.claimsissuer)
				?.valueString || '';
		const JWTSigningAlgorithmValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.jwtsigningalgorithm)
				?.valueString || '';
		const privateKeyValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.dicomprivatekey)
				?.valueString || '';
		const storeURLValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.storeuri)
				?.valueString || '';
		const authorizationURLValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.authorizationuri)
				?.valueString || '';
		const clientIDValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.clientid)
				?.valueString || '';
		const clientSecretValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.dicomclientsecret)
				?.valueString || '';
		const scopeValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.scopes)?.valueString ||
			'';
		const tokenURIValue =
			deviceOpen?.device?.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.tokenuri)
				?.valueString || '';

		const getSendToDicomWeb = resource => {
			const features = resource?.find(elem => elem.url === fhirExtensionUrls.device.features)?.extension;

			if (features) {
				return !!features.find(element => element.valueCoding.code === 'DicomStore');
			}
		};

		const getQueryRetrieveFromDicomWeb = resource => {
			const features = resource?.find(elem => elem.url === fhirExtensionUrls.device.features)?.extension;

			if (features) {
				return !!features.find(element => element.valueCoding.code === 'QueryRetrieve');
			}
		};

		const getAuthenticationType = () =>
			deviceOpen.device.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.authenticationtype)
				?.valueInteger;

		const defaultValues = useMemo(() => {
			if (deviceOpen?.device) {
				return {
					active: deviceOpen.device.status === 'active',
					deviceType: getDeviceType(deviceOpen.device),
					deviceName: deviceOpen?.device.distinctIdentifier,
					AETitle: deviceOpen.device.extension.find(i => i.url === fhirExtensionUrls.device.aeTitle)
						?.valueString,
					port: deviceOpen.device.extension.find(i => i.url === fhirExtensionUrls.device.dicomServerPort)
						?.valueString,
					role: deviceOpen.device.extension.find(i => i.url === fhirExtensionUrls.practitionerRole.role)
						?.valueReference.display,
					receivingManagingOrganizationAs: deviceOpen.device.extension.find(
						i => i.url === fhirExtensionUrls.device.managingOrganization
					)?.valueReference.display,
					receivingImagingOrganizationAs: deviceOpen.device.extension.find(
						i => i.url === fhirExtensionUrls.device.imagingOrganization
					)?.valueReference.display,
					receiveAssigningAuthorityAs: deviceOpen.device.extension.find(
						i => i.url === fhirExtensionUrls.organization.issuer
					)?.valueReference.display,
					receiveStudyAs: deviceOpen.device.extension.find(
						i => i.url === fhirExtensionUrls.device.receiveStudyAs
					)?.valueReference.display,
					hideRejectedInstances: deviceOpen.device.extension.find(
						i => i.url === fhirExtensionUrls.device.hideRejectedInstances
					)?.valueBoolean,
					sendToDicomWeb: getSendToDicomWeb(deviceOpen.device.extension),
					queryRetrieveFromDicomWeb: getQueryRetrieveFromDicomWeb(deviceOpen.device.extension),
					searchURL:
						deviceOpen.device.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.searchuri)
							?.valueString || '',
					retrieveURL:
						deviceOpen.device.extension.find(i => i.url === fhirExtensionUrls.device.dicomweb.retrieveuri)
							?.valueString || '',
					authenticationType:
						getAuthenticationType() === 0 ? 'basic' : getAuthenticationType() === 1 ? 'jwt' : 'dicom',
					storeURL: storeURLValue,
					authorizationURL: authorizationURLValue,
					clientID: clientIDValue,
					clientSecret: clientSecretValue,
					scope: scopeValue,
					tokenURI: tokenURIValue,
					claimIssuer: claimIssuerValue,
					JWTSigningAlgorithm: JWTSigningAlgorithmValue,
					privateKey: privateKeyValue,
					name: nameValue,
					password: passwordValue,
				};
			}
			return {
				active: true,
				deviceType: 'OmegaAI Link',
				deviceName: newOmegaAIName,
				receivingManagingOrganizationAs: orgDataName,
				receiveAssigningAuthorityAs: orgDataName,
				receiveStudyAs: 'COMPLETED',
				authenticationType: 'dicom',
				hideRejectedInstances: true,
				sendToDicomWeb: true,
				queryRetrieveFromDicomWeb: true,
			};
		}, [deviceOpen.device, newOmegaAIName]);

		const { control, watch, getValues, setValue } = useForm({
			resolver: yupResolver(validationSchema),
			defaultValues,
		});

		const watchDeviceType = watch('deviceType');
		const watchDeviceName = watch('deviceName');
		const watchAETitle = watch('AETitle');
		const watchPort = watch('port');
		const watchRole = watch('role');
		const watchStatus = watch('receiveStudyAs');
		const watchAuthenticationType = watch('authenticationType');

		useEffect(() => {
			if (watchDeviceName && watchAETitle && watchPort) {
				setSaveDeviceActive(true);
			} else {
				setSaveDeviceActive(false);
			}
		}, [watchDeviceName, watchAETitle, watchPort]);

		useEffect(() => {
			if (deviceOpen.mode === 'add') {
				watchDeviceType === 'OmegaAI Link'
					? setValue('deviceName', newOmegaAIName)
					: setValue('deviceName', newDicomWEBName);
			}
		}, [watchDeviceType, newOmegaAIName, newDicomWEBName]);

		useEffect(() => {
			if (watchAuthenticationType === 'dicom') {
				setValue('storeURL', storeURLValue);
				setValue('authorizationURL', authorizationURLValue);
				setValue('clientID', clientIDValue);
				setValue('clientSecret', clientSecretValue);
				setValue('scope', scopeValue);
			}
			if (watchAuthenticationType === 'jwt') {
				setValue('storeURL', storeURLValue);
				setValue('tokenURI', tokenURIValue);
				setValue('claimIssuer', claimIssuerValue);
				setValue('JWTSigningAlgorithm', JWTSigningAlgorithmValue);
				setValue('privateKey', privateKeyValue);
			}
			if (watchAuthenticationType === 'basic') {
				setValue('name', nameValue);
				setValue('password', passwordValue);
			}
		}, [watchAuthenticationType]);

		useEffect(() => {
			const getActualRole = async () => {
				const { data } = await axios.get(
					`${__config.data_sources.breeze}/RoleOmegaAI/${accountData?.AccountId}?Organization=${orgDataId}`
				);
				if (data.entry) {
					const rolesData = [];
					data?.entry.map(item => {
						rolesData.push({ id: item.resource.id, option: item.resource.name });
					});
					setRoles(rolesData);
				}
			};

			const getActualStudyAs = async () => {
				const { data } = await axios.get(
					`${__config.data_sources.breeze}/StudyStatusOmegaAI/${accountData?.AccountId}?organizationid=${orgDataId}`
				);
				if (data.entry) {
					const studyAsData = [];
					data?.entry.map(item => {
						studyAsData.push({
							id: item.resource.id,
							option: item.resource.status,
							statusValue: item.resource.statusValue,
						});
					});
					studyAsData.sort((a, b) => a.statusValue - b.statusValue);
					setStudyAs(studyAsData);
				}
			};

			getActualRole();
			getActualStudyAs();
		}, []);

		useImperativeHandle(ref, () => ({
			async onSubmit() {
				const valuesData = getValues();
				const currentRole = roles.find(item => item.option === watchRole);
				const currentStatus = studyAs.find(item => item.option === watchStatus);

				const payload = {
					resourceType: 'Device',
					distinctIdentifier: valuesData.deviceName, // Device name
					type: {
						coding: [
							{
								code: valuesData.deviceType === 'DICOM Web' ? 'dicom-web' : 'OmegaAILink', // Device type
							},
						],
					},

					status: valuesData.active ? 'active' : 'inActive', // Device status

					extension: [
						{
							url: fhirExtensionUrls.device.managingOrganization, // Receive Managing Organization As
							valueReference: {
								id: orgDataId,
								reference: `Organization/${orgDataId}`,
								display: orgDataName,
							},
						},
						{
							url: 'isActive',
							valueBoolean: true, // Active
						},
						{
							url: fhirExtensionUrls.device.aeTitle, // AETitle
							valueString: valuesData.AETitle,
						},
						{
							url: fhirExtensionUrls.organization.issuer, // Receive Assigning Authority As
							valueReference: {
								id: orgDataId,
								reference: `/AssigningAuthority/${orgDataId}`,
								display: orgDataName,
							},
						},
					],
				};

				if (valuesData.deviceType === 'OmegaAI Link') {
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomServerPort, // Port
						valueString: valuesData.port,
					});
				}

				if (currentRole) {
					payload.extension.push({
						url: fhirExtensionUrls.practitionerRole.role, // Role
						valueReference: {
							id: currentRole.id,
							reference: `Role/${currentRole.id}`,
							display: currentRole.option,
						},
					});
				}

				if (currentStatus) {
					payload.extension.push({
						url: fhirExtensionUrls.device.receiveStudyAs, // Receive Study As
						valueReference: {
							id: currentStatus.id,
							reference: `WorkflowStep/${currentStatus.id}`,
							display: currentStatus.option,
						},
					});
				}

				if (valuesData.deviceType === 'DICOM Web') {
					payload.extension.push({
						url: fhirExtensionUrls.device.hideRejectedInstances, // Hide rejected instances
						valueBoolean: valuesData.hideRejectedInstances,
					});
				}

				if (valuesData.deviceType === 'DICOM Web' && valuesData.sendToDicomWeb) {
					if (payload.extension.find(elem => elem.url === fhirExtensionUrls.device.features)?.extension) {
						payload.extension
							.find(elem => elem.url === fhirExtensionUrls.device.features)
							.extension.push({
								url: 'feature', // Dicom Store
								valueCoding: {
									code: 'DicomStore',
									display: 'Send to Device',
								},
							});
					} else {
						payload.extension.push({
							url: fhirExtensionUrls.device.features, // Features
							extension: [
								{
									url: 'feature', // Dicom Store
									valueCoding: {
										code: 'DicomStore',
										display: 'Send to Device',
									},
								},
							],
						});
					}
				}

				if (valuesData.deviceType === 'DICOM Web' && valuesData.queryRetrieveFromDicomWeb) {
					if (payload.extension.find(elem => elem.url === fhirExtensionUrls.device.features)?.extension) {
						payload.extension
							.find(elem => elem.url === fhirExtensionUrls.device.features)
							.extension.push({
								url: 'feature', // Query Retrieve
								valueCoding: {
									code: 'QueryRetrieve',
									display: 'Query/Retrieve from Device',
								},
							});
					} else {
						payload.extension.push({
							url: fhirExtensionUrls.device.features, // Features
							extension: [
								{
									url: 'feature', // Query Retrieve
									valueCoding: {
										code: 'QueryRetrieve',
										display: 'Query/Retrieve from Device',
									},
								},
							],
						});
					}
				}

				if (valuesData.deviceType === 'DICOM Web' && valuesData.authenticationType === 'basic') {
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.authenticationtype, // Authentication Type
						valueInteger: 0,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.searchuri, // Search URL
						valueString: valuesData.searchURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.retrieveuri, // Retrieve URL
						valueString: valuesData.retrieveURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.username, // Name
						valueString: valuesData.name,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.dicompassword, // Password
						valueString: valuesData.password,
					});
				}

				if (valuesData.deviceType === 'DICOM Web' && valuesData.authenticationType === 'jwt') {
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.authenticationtype, // Authentication Type
						valueInteger: 1,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.searchuri, // Search URL
						valueString: valuesData.searchURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.retrieveuri, // Retrieve URL
						valueString: valuesData.retrieveURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.storeuri, // Store URL
						valueString: valuesData.storeURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.tokenuri, // Token URI
						valueString: valuesData.tokenURI,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.claimsissuer, // Claim Issuer
						valueString: valuesData.claimIssuer,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.jwtsigningalgorithm, // JWT Signing Algorithm
						valueString: valuesData.JWTSigningAlgorithm,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.dicomprivatekey, // Private Key
						valueString: valuesData.privateKey,
					});
				}

				if (valuesData.deviceType === 'DICOM Web' && valuesData.authenticationType === 'dicom') {
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.authenticationtype, // Authentication Type
						valueInteger: 2,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.searchuri, // Search URL
						valueString: valuesData.searchURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.retrieveuri, // Retrieve URL
						valueString: valuesData.retrieveURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.storeuri, // Store URL
						valueString: valuesData.storeURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.authorizationuri, // Store URL
						valueString: valuesData.authorizationURL,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.clientid, // Store URL
						valueString: valuesData.clientID,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.dicomclientsecret, // Store URL
						valueString: valuesData.clientSecret,
					});
					payload.extension.push({
						url: fhirExtensionUrls.device.dicomweb.scopes, // Store URL
						valueString: valuesData.scope,
					});
				}

				let result;

				try {
					if (deviceOpen?.mode === 'edit') {
						payload.id = deviceOpen.device.id;
						const finalExtension = [...deviceOpen.device.extension];

						finalExtension.forEach((item, index) => {
							const currentElem = payload.extension.find(elem => elem.url === item.url);

							if (currentElem) {
								finalExtension[index] = currentElem;
							}
						});

						if (valuesData.deviceType === 'DICOM Web') {
							payload.extension.forEach(item => {
								if (!finalExtension.find(elem => elem.url === item.url)) {
									finalExtension.push(item);
								}
							});
						}

						const finalPayload = {
							...deviceOpen.device,
							...payload,
							extension: finalExtension,
						};

						const { data } = await axios.put(
							`${__config.data_sources.breeze}/DeviceOmegaAI/${accountData?.AccountId}/${deviceOpen.device.id}`,
							finalPayload
						);
						result = data;
					} else {
						const { data } = await axios.post(
							`${__config.data_sources.breeze}/DeviceOmegaAI/register/${accountData?.AccountId}`,
							payload
						);
						result = data;
					}
				} catch (e) {
					console.error(e);
				}

				return result;
			},
		}));

		const onDownload = () => {
			if (deviceOpen?.device?.id) {
				axios({
					url: `${__config.data_sources.breeze}/DeviceOmegaAI/OmegaAILinkInstaller/${accountData?.AccountId}/${deviceOpen.device.id}`,
					method: 'GET',
					responseType: 'blob',
				}).then(response => {
					const objectUrl = window.URL.createObjectURL(
						new Blob([response.data], {
							type: response.headers['content-type'],
						})
					);
					const disposition = response.headers['content-disposition'];
					const fileName = disposition && disposition.slice(disposition.indexOf('=') + 1);

					const link = document.createElement('a');
					link.href = objectUrl;
					link.download = fileName;
					link.click();
					link.remove();

					setTimeout(() => {
						window.URL.revokeObjectURL(objectUrl);
					}, 15000);

					setDeviceOpen({ open: false, mode: 'add' });
				});
			} else {
				ref.current.onSubmit().then(res => {
					const newDeviceId = res.ID;
					axios({
						url: `${__config.data_sources.breeze}/DeviceOmegaAI/OmegaAILinkInstaller/${accountData?.AccountId}/${newDeviceId}`,
						method: 'GET',
						responseType: 'blob',
					}).then(response => {
						const objectUrl = window.URL.createObjectURL(
							new Blob([response.data], {
								type: response.headers['content-type'],
							})
						);
						const disposition = response.headers['content-disposition'];
						const fileName = disposition && disposition.slice(disposition.indexOf('=') + 1);

						const link = document.createElement('a');
						link.href = objectUrl;
						link.download = fileName;
						link.click();
						link.remove();

						setTimeout(() => {
							window.URL.revokeObjectURL(objectUrl);
						}, 15000);

						setDeviceOpen({ open: false, mode: 'add' });
					});
				});
			}
		};

		const handleChange = value => {
			switch (value) {
				case 'general':
					setGeneralInfoWrapped(prev => !prev);
					break;
				case 'DicomWebFeatures':
					setDicomWebFeaturesWrapped(prev => !prev);
					break;
				case 'authenticationType':
					setAuthenticationTypeWrapped(prev => !prev);
					break;
			}
		};

		const deviceTypeData = [
			{ id: 1, option: 'OmegaAI Link' },
			{ id: 2, option: 'DICOM Web' },
		];

		const authenticationTypeData = [
			{ id: 1, option: 'dicom' },
			{ id: 2, option: 'jwt' },
			{ id: 3, option: 'basic' },
		];

		return (
			<Box sx={classes.deviceTabBox}>
				<Collapsible>
					<Box className="handle" onClick={() => handleChange('general')}>
						<Typography>General Information</Typography>
						{generalInfoWrapped ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</Box>

					<Box data-testid="add-device-form">
						<Box component="form">
							{!generalInfoWrapped ? (
								<Box sx={classes.main}>
									<FormSwitch control={control} label="Active" name="active" />
									<FormSelect
										isRequired
										isSingle
										control={control}
										data={deviceTypeData}
										label="Device Type"
										name="deviceType"
										selectDisabled={deviceOpen.mode === 'edit'}
									/>

									<Box sx={classes.row}>
										<FormInput
											isRequired
											control={control}
											disabledInput={false}
											label="Device Name"
											name="deviceName"
										/>
										<FormInput
											isRequired
											control={control}
											disabledInput={false}
											label="AE Title"
											name="AETitle"
										/>
									</Box>

									<Box sx={classes.row}>
										{watchDeviceType === 'OmegaAI Link' ? (
											<FormInput
												isRequired
												control={control}
												disabledInput={false}
												label="Port"
												name="port"
											/>
										) : (
											<FormInput
												disabledInput
												control={control}
												isRequired={false}
												label="Receiving Imaging Organization As"
												name="receivingImagingOrganizationAs"
											/>
										)}

										<FormSelect
											control={control}
											data={roles}
											isRequired={false}
											isSingle={false}
											label="Role"
											name="role"
											selectDisabled={roles.length <= 0}
										/>
									</Box>

									<Box sx={classes.row}>
										<FormInput
											disabledInput
											control={control}
											isRequired={false}
											label="Receiving Managing Organization As"
											name="receivingManagingOrganizationAs"
										/>
										<FormInput
											disabledInput
											control={control}
											isRequired={false}
											label="Receive Assigning Authority As"
											name="receiveAssigningAuthorityAs"
										/>
									</Box>

									<Box sx={classes.row}>
										<FormSelect
											control={control}
											data={studyAs}
											isRequired={false}
											label="Receive Study Status As"
											name="receiveStudyAs"
											selectDisabled={studyAs.length <= 0}
										/>
									</Box>

									{watchDeviceType === 'OmegaAI Link' ? (
										<Box sx={classes.dicomLinkButtonsBox}>
											<Typography sx={classes.label}>Install Device</Typography>
											<Box>
												<Button sx={classes.dicomLinkButton} onClick={onDownload}>
													<svg
														fill="none"
														height="12"
														viewBox="0 0 12 12"
														width="12"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M2.00008 11.3346C1.63341 11.3346 1.31953 11.2041 1.05841 10.943C0.797304 10.6819 0.666748 10.368 0.666748 10.0013V8.0013H2.00008V10.0013H10.0001V8.0013H11.3334V10.0013C11.3334 10.368 11.2029 10.6819 10.9417 10.943C10.6806 11.2041 10.3667 11.3346 10.0001 11.3346H2.00008ZM6.00008 8.66797L2.66675 5.33464L3.60008 4.36797L5.33341 6.1013V0.667969H6.66675V6.1013L8.40008 4.36797L9.33341 5.33464L6.00008 8.66797Z"
															fill="white"
														/>
													</svg>
													<Typography sx={classes.dicomLinkButtonLabel}>Download</Typography>
												</Button>
												<Button sx={classes.dicomLinkButton}>
													<svg
														fill="none"
														height="14"
														viewBox="0 0 8 14"
														width="8"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M3.66667 13.6654C2.64444 13.6654 1.77778 13.3098 1.06667 12.5987C0.355556 11.8876 0 11.0209 0 9.9987V2.9987C0 2.26536 0.261111 1.63759 0.783333 1.11536C1.30556 0.593142 1.93333 0.332031 2.66667 0.332031C3.4 0.332031 4.02778 0.593142 4.55 1.11536C5.07222 1.63759 5.33333 2.26536 5.33333 2.9987V9.33203C5.33333 9.7987 5.17222 10.1931 4.85 10.5154C4.52778 10.8376 4.13333 10.9987 3.66667 10.9987C3.2 10.9987 2.80556 10.8376 2.48333 10.5154C2.16111 10.1931 2 9.7987 2 9.33203V2.9987H3V9.33203C3 9.52092 3.06389 9.67925 3.19167 9.80703C3.31944 9.93481 3.47778 9.9987 3.66667 9.9987C3.85556 9.9987 4.01389 9.93481 4.14167 9.80703C4.26944 9.67925 4.33333 9.52092 4.33333 9.33203V2.9987C4.33333 2.53203 4.17222 2.13759 3.85 1.81536C3.52778 1.49314 3.13333 1.33203 2.66667 1.33203C2.2 1.33203 1.80556 1.49314 1.48333 1.81536C1.16111 2.13759 1 2.53203 1 2.9987V9.9987C1 10.732 1.26111 11.3598 1.78333 11.882C2.30556 12.4043 2.93333 12.6654 3.66667 12.6654C4.4 12.6654 5.02778 12.4043 5.55 11.882C6.07222 11.3598 6.33333 10.732 6.33333 9.9987V2.9987H7.33333V9.9987C7.33333 11.0209 6.97778 11.8876 6.26667 12.5987C5.55556 13.3098 4.68889 13.6654 3.66667 13.6654Z"
															fill="white"
														/>
													</svg>
													<Typography sx={classes.dicomLinkButtonLabel}>Copy link</Typography>
												</Button>
											</Box>
										</Box>
									) : (
										''
									)}

									{watchDeviceType === 'DICOM Web' ? (
										<Box sx={classes.row}>
											<FormSwitch
												control={control}
												label="Hide Reject Instances"
												name="hideRejectedInstances"
											/>
										</Box>
									) : (
										''
									)}
								</Box>
							) : (
								''
							)}

							{watchDeviceType === 'DICOM Web' ? (
								<Box>
									<Box className="handle" onClick={() => handleChange('DicomWebFeatures')}>
										<Typography>DICOM Web Features</Typography>
										{dicomWebFeaturesWrapped ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
									</Box>
									{!dicomWebFeaturesWrapped ? (
										<Box sx={classes.main}>
											<Box sx={classes.rowFirst}>
												<FormSwitch
													control={control}
													label="Send to DICOM Web"
													name="sendToDicomWeb"
													stylesBox={{ flex: '0 1 44%' }}
												/>
												<FormSwitch
													control={control}
													label="Query/Retrieve from Dicom Web"
													name="queryRetrieveFromDicomWeb"
													stylesBox={{ flex: '0 1 56%' }}
												/>
											</Box>
										</Box>
									) : (
										''
									)}

									<Box className="handle" onClick={() => handleChange('authenticationType')}>
										<Typography>Authentication Type</Typography>
										{authenticationTypeWrapped ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
									</Box>
									{!authenticationTypeWrapped ? (
										<Box sx={classes.main}>
											<Box sx={classes.rowFirst}>
												<FormSelect
													control={control}
													data={authenticationTypeData}
													isRequired={false}
													isSingle={false}
													label="Authentication Type"
													name="authenticationType"
													selectDisabled={false}
												/>
											</Box>
											{watchAuthenticationType === 'dicom' ? (
												<Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Search URL"
															name="searchURL"
														/>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Retrieve URL"
															name="retrieveURL"
														/>
													</Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Store URL"
															name="storeURL"
														/>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Authorization URL"
															name="authorizationURL"
														/>
													</Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Client ID"
															name="clientID"
														/>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Client Secret"
															name="clientSecret"
														/>
													</Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Scope"
															name="scope"
														/>
													</Box>
												</Box>
											) : watchAuthenticationType === 'jwt' ? (
												<Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Search URL"
															name="searchURL"
														/>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Retrieve URL"
															name="retrieveURL"
														/>
													</Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Store URL"
															name="storeURL"
														/>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Token URI (Audience)"
															name="tokenURI"
														/>
													</Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Claim Issuer (iss)"
															name="claimIssuer"
														/>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="JWT Signing Algorithm"
															name="JWTSigningAlgorithm"
														/>
													</Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Private Key"
															name="privateKey"
														/>
													</Box>
												</Box>
											) : (
												<Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Search URL"
															name="searchURL"
														/>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Retrieve URL"
															name="retrieveURL"
														/>
													</Box>
													<Box sx={classes.row}>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Name"
															name="name"
														/>
														<FormInput
															control={control}
															disabledInput={false}
															isRequired={false}
															label="Password"
															name="password"
														/>
													</Box>
												</Box>
											)}
										</Box>
									) : (
										''
									)}
								</Box>
							) : (
								''
							)}
						</Box>
					</Box>
				</Collapsible>
			</Box>
		);
	}
);

export default AddEditDeviceTab;
