import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';

import { SelectorContextProvider } from '@rs-core/context/SelectorContext';
import { CRMSearchScopeProvider } from '@rs-core/context/CRMSearchScopeContext';
import { lightTheme } from '@worklist-2/ui/src/themes';

import Crm from './routes/CRM';
import BreezeLogin from './routes/BreezeLogin';
import HelpCenter from './components/CRM/Documentation/HelpCenter/HelpCenter';

import { ThemeProvider } from '@mui/material/styles';

const App = () => (
	<ThemeProvider theme={lightTheme}>
		<UnauthenticatedTemplate>
			<SelectorContextProvider>
				<CRMSearchScopeProvider>
					<Routes>
						<Route element={<BreezeLogin />} path="*" />
						{/* <Route path="/*" element={<Crm />} /> */}
					</Routes>
				</CRMSearchScopeProvider>
			</SelectorContextProvider>
		</UnauthenticatedTemplate>
		<AuthenticatedTemplate>
			<SelectorContextProvider>
				<CRMSearchScopeProvider>
					<Routes>
						<Route element={<HelpCenter theme="OAI" />} path="/documentation/help-center/omegaai/*" />
						<Route element={<HelpCenter theme="Blume" />} path="/documentation/help-center/blume/*" />
						<Route element={<Crm />} path="/*" />
					</Routes>
				</CRMSearchScopeProvider>
			</SelectorContextProvider>
		</AuthenticatedTemplate>
	</ThemeProvider>
);

export default App;
