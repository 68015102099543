import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import { useConfig } from '@worklist-2/core/src';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import Toast from '@worklist-2/ui/src/components/Toast';

import { TabPanel } from '../../../Cases/casePopOverStyles';
import BlumeOAIAssets from './BlumeOAIAssets';

const TABLE_HEAD = ['Asset Name', 'Product Name', 'SW Version #', 'Serial #'];

const Assets = ({ data, orgId }) => {
	const [blumeOAIAssets, setBlumeOAIAssets] = useState([]);
	const [toastMsg, setToastMsg] = useState('');

	const params = useParams();
	const phoenixBlumeIsAssetInBreeze = useBooleanFlagValue('phoenix-blume-is-asset-in-breeze');
	const __config = useConfig();

	useEffect(() => {
		if (!phoenixBlumeIsAssetInBreeze || !orgId) return;

		fetchAssets();
	}, [__config.data_sources.fhir, fetchAssets, orgId, phoenixBlumeIsAssetInBreeze]);

	const fetchAssets = useCallback(async () => {
		// Blume/OAI Assets
		const res = await axios.get(`${__config.data_sources.breeze}/OrganizationOmegaAI/${params.id}/${orgId}/Asset`);

		if (res?.status === 403) return setToastMsg("You don't have access to this organization");

		if (res?.status !== 200 && !res?.data) throw new Error('No assets found');

		setBlumeOAIAssets(res.data);
	}, [__config.data_sources?.breeze, orgId, params.id]);

	const handleSwitchToggle = useCallback(
		async asset => {
			const payload = [asset];
			const name = asset.Asset.trim().toLowerCase();
			const isActive = asset.Active.toLowerCase() === 'true';

			// Add condition for Blume Automated Front Desk
			if (name === 'blume automated front desk' && isActive) {
				payload.push({
					Asset: 'Blume Patient Portal',
					Active: 'False',
				});
			} else if (name === 'blume patient portal' && isActive) {
				payload.push({
					Asset: 'Blume Automated Front Desk',
					Active: 'False',
				});
			}

			const res = await axios.post(
				`${__config.data_sources.breeze}/OrganizationOmegaAI/${params.id}/${orgId}/Asset`,
				payload
			);

			if (res.status !== 200) throw new Error(`Could not update asset. Status code: ${res.status}`);

			fetchAssets();
		},
		[__config.data_sources?.breeze, fetchAssets, orgId, params.id]
	);

	const handleToastClose = () => {
		setToastMsg('');
	};

	return (
		<TabPanel className="invisible-scrollbar">
			<div className="header">
				<div className="left">
					<div className="colored-icon" style={{ backgroundColor: 'rgba(111, 207, 161, 0.2)' }}>
						<Inventory2OutlinedIcon sx={{ fontSize: '20px', color: '#6FCFA1' }} />
					</div>
					<h2>Assets</h2>
				</div>
			</div>
			<Toast message={toastMsg} open={Boolean(toastMsg)} onClose={handleToastClose} />

			{phoenixBlumeIsAssetInBreeze ? (
				<Box
					sx={{
						margin: '20px 0',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
						borderRadius: '10px',
					}}
				>
					{blumeOAIAssets.map(item => (
						<BlumeOAIAssets
							key={item?.Asset}
							active={item.Active}
							features={item.Feature}
							name={item.Asset}
							onToggle={handleSwitchToggle}
						/>
					))}
					{blumeOAIAssets.length === 0 ? (
						<Box
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Typography
								sx={{
									textAlign: 'center',
									fontSie: '16px',
									margin: '8px 0',
									color: 'black',
								}}
							>
								No Result
							</Typography>
						</Box>
					) : null}
				</Box>
			) : (
				<TableContainer
					style={{
						marginTop: '4px',
						height: '296px',
						border: 0,
						borderRadius: 0,
						borderTopRightRadius: '20px',
						borderTopLeftRadius: '20px',
						boxShadow: 'none',
					}}
					sx={{
						'&::-webkit-scrollbar-thumb': {
							background: '#C4C4C4 !important',
							borderRadius: '3px',
						},

						'&::-webkit-scrollbar-track': {
							background: '#FFF !important',
						},
					}}
				>
					<Table stickyHeader aria-label="customized table">
						<TableHead>
							<TableRow sx={{ height: '30px' }}>
								{TABLE_HEAD.map(title => (
									<TableCell
										align="center"
										sx={{ padding: 0, fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)', border: 0 }}
									>
										{title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody>
							{data && data.length > 0 ? (
								data.map((item, i) => (
									<StyledTableRow key={i}>
										<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
											{item?.resource.AssetName || '-'}
										</TableCell>
										<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
											{item?.resource.ProductName || '-'}
										</TableCell>
										<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
											{item?.resource.SwVersionNumber || '-'}
										</TableCell>
										<TableCell align="center" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
											{item?.resource.SerialNumber || '-'}
										</TableCell>
									</StyledTableRow>
								))
							) : (
								<StyledTableRow>
									<TableCell colSpan={4}>
										<Box
											sx={{
												height: '100%',
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<Typography
												sx={{
													textAlign: 'center',
													fontSie: '16px',
													margin: '8px 0',
													color: 'black',
												}}
											>
												No Result
											</Typography>
										</Box>
									</TableCell>
								</StyledTableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</TabPanel>
	);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&': {
		color: theme.palette.common.black,
	},
	'&:hover td': {
		backgroundColor: '#C8D6FF',
	},
	'& td': {
		height: '38px',
		padding: 0,
		alignItems: 'center',
		border: 'none',
	},
	'&:nth-of-type(odd)': {
		backgroundColor: '#F9FBFF',
	},
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.common.white,
	},
}));

export default Assets;
