import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import { BrowserRouter as Router } from 'react-router-dom';
import { CypressHistorySupport } from 'cypress-react-router';
import { AppModeProvider, ConfigProvider } from '@worklist-2/core/src';
import { UserAuthProvider } from '@rs-core/context/UserAuthContext';
import { OpenFeatureProvider, OpenFeature } from '@openfeature/react-sdk';
import DevCycleProvider from '@devcycle/openfeature-web-provider';

const config = JSON.parse(process.env.NODE_CONFIG);

const userContext = {
	kind: 'multi',
	entity: {
		name: `${config.resource_group}`.trim(),
		key: `${config.resource_group}`.trim().toUpperCase(),
	},
};

const devCycleUserContext = {
	user_id: 'DEFAULT',
	customData: {
		entity: `${config.resource_group}`.trim().toUpperCase(),
	},
};

const devCycleProvider = new DevCycleProvider(config.devCycleKey, {
	disableRealtimeUpdates: true,
});
OpenFeature.setContext(devCycleUserContext);
await OpenFeature.setProviderAndWait(devCycleProvider);

ReactDOM.render(
	<React.StrictMode>
		<OpenFeatureProvider suspend={true}>
			<Suspense fallback={null}>
				<ConfigProvider>
					<Router basename={config.breeze_router_basename}>
						<CypressHistorySupport />
						<AppModeProvider>
							<Suspense fallback={null}>
								<UserAuthProvider>
									<App />
								</UserAuthProvider>
							</Suspense>
						</AppModeProvider>
					</Router>
				</ConfigProvider>
			</Suspense>
		</OpenFeatureProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
