import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment';

import { useIsMobile } from '@rs-core/utils/responsiveUtils';
import { useAuth } from '@rs-core/context/UserAuthContext';

import MobileLabelText from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileLabelText';
import MobileStudyStatus from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyStatus';
import MobileTitle from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileTitle';
import getInfoPanelPatientName from '@worklist-2/patientPortal/src/views/StudyInfoView/StudyDetailedView/getInfoPanelPatientName';

const MobileStudyInformationSection = ({ study }) => {
	const isMobile = useIsMobile();
	const { t } = useTranslation('studyDetail');
	const { patientMapping } = useAuth();

	return (
		<Box>
			<MobileTitle title="Study Information" />

			<Grid container>
				<Grid item xs={isMobile ? 7 : 3.5}>
					<MobileLabelText
						label={t('Status')}
						text={
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									textTransform: 'uppercase',
								}}
							>
								<MobileStudyStatus status={study?.status} />
								{study?.status === 'INPROCESS' ? t('PROCESSING') : t(study?.status?.toUpperCase())}
							</Box>
						}
					/>
				</Grid>
				<Grid item xs={isMobile ? 5 : 3.5}>
					<MobileLabelText label={t('Patient Name')} text={getInfoPanelPatientName(patientMapping, study)} />
				</Grid>

				<Grid item xs={isMobile ? 7 : 3.5}>
					<MobileLabelText
						label={t('Study Date/Time')}
						text={
							study?.studyDisplayDatetime || study?.createdTimeUTC
								? moment(study?.studyDisplayDatetime || study?.createdTimeUTC).format(
										'MMM DD, YYYY H:mm A'
								  )
								: '-'
						}
					/>
				</Grid>

				<Grid item xs={isMobile ? 5 : 3.5}>
					<MobileLabelText label={t('Imaging Organization')} text={study?.imagingOrganization?.name} />
				</Grid>

				<Grid item xs={isMobile ? 7 : 3.5}>
					<MobileLabelText label={t('Description')} text={study?.description} />
				</Grid>

				<Grid item xs={isMobile ? 7 : 3.5}>
					<MobileLabelText label={t('Managing Organization')} text={study?.managingOrganization?.name} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default MobileStudyInformationSection;
