import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Box, Typography, CircularProgress } from '@mui/material';
import { ChatOutlined, FolderOutlined } from '@mui/icons-material';

import { crmSearchScopes, useCRMDataLoader, useAuth, useConfig } from '@worklist-2/core/src';

import CommentFiles from './Attachments/CommentFiles';
import DetailComments from './DetailComments';

import { HeaderActionButton, TabPanel } from '../../../casePopOverStyles';

const Comments = ({
	caseData,
	commentData,
	setCommentData,
	attachments,
	setAttachments,
	onChangeData,
	sendPrivate,
	setSendPrivate,
	progress,
	setProgress,
}) => {
	const { loggedInUser } = useAuth();
	const __config = useConfig();

	const [showFiles, toggleFiles] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [editComment, setEditComment] = useState('');
	const [users, setUsers] = useState([]);

	const commentDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.commentApi,
	});
	const userDataLoader = useCRMDataLoader({
		scope: crmSearchScopes.userApi,
	});

	useEffect(() => {
		fetchData().catch(console.error);
	}, [caseData]);

	const fetchData = async loader => {
		const fnName = 'load';
		const bodyData = undefined;
		if (!loader) {
			loader = commentDataLoader;
		}

		setLoading(true);
		setData(null);

		try {
			await loader[fnName](
				{ casenumber: caseData.CaseNumber, sort: '-created' },
				!!bodyData || undefined,
				bodyData
			).then(result => {
				const newData = result;
				setData(newData);
				setLoading(false);
			});
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	useEffect(() => {
		async function asynchronousEffect() {
			try {
				if (!loggedInUser.permission?.User.Read) {
					return;
				}

				await userDataLoader.load({ summary: true }).then(result => {
					const usersList = [];
					if (result) {
						result.filter(user => {
							usersList.push({
								id: user.UserId,
								name: user.UserName,
							});
						});
					}
					setUsers(usersList);
				});
			} catch (e) {
				console.error(e);
			}
		}

		asynchronousEffect();
	}, []);

	const submitComment = async e => {
		let parentId;
		if (editComment.split('_')[0] === 'reply') {
			parentId = editComment.split('_')[1];
			const replyComment = _.find(data, { CommentId: parentId });
			if (replyComment?.LastUpdatedUserId?.toLowerCase() === loggedInUser.id?.toLowerCase()) {
				parentId = undefined;
			}
		}

		if (commentData) {
			const commentTex = commentData;
			if (commentTex?.trim() !== '' || attachments?.length > 0) {
				setLoading(true);
				await onChangeData(e, parentId).then(result => {
					setData([result.data, ...data]);
					setLoading(false);
					setEditComment(`reply_${result.data?.CommentId}`);
				});
			}
		}
	};

	const updateComment = async (commentDataInput, fieldChanged, watcherData) => {
		let result = '';

		let fieldName = [];
		let fieldValue = [];

		switch (fieldChanged) {
			case 'IsPrivate':
				fieldName.push(fieldChanged);
				fieldValue.push(commentDataInput.isPrivate ? !commentDataInput.isPrivate : true);
				break;
			case 'Bookmark':
				const tempBookmark = commentDataInput.bookmark ? commentDataInput.bookmark.split(',') : [];
				if (tempBookmark.includes(loggedInUser.id)) {
					_.remove(tempBookmark, item => item === loggedInUser.id);
				} else {
					tempBookmark.push(loggedInUser.id);
				}

				fieldName = fieldChanged;
				fieldValue = tempBookmark.join(',');
				break;
			case 'Description':
				const payload = {
					CaseId: commentDataInput.caseId,
					CaseNumber: commentDataInput.caseNumber,
					CommentId: commentDataInput.commentId,
					CreatedDateTime: commentDataInput.createdDateTime,
					Description: commentDataInput.comment,
					ResourceType: 'Comment',
					ParentCommentId: commentDataInput.parentCommentId,
					IsPrivate: commentDataInput.isPrivate,
					Bookmark: commentDataInput.bookmark,
					Attachments: commentDataInput.attachments,
					Watcher: commentDataInput.watcher,
				};

				result = await commentDataLoader.update(payload.CommentId, payload, setProgress).then(rs => {
					const editedComment = rs;
					const tempData = data.map(item =>
						item.CommentId !== commentDataInput.commentId ? item : editedComment
					);
					setData(tempData);
					setEditComment('');
					setProgress(0);
				});

				return result;
			case 'Watcher':
				result = await axios
					.patch(`${__config.data_sources.breeze}/Comment/${watcherData.commentId}`, watcherData.payload)
					.then(res => {
						const tempData = data.map(item => (item.CommentId !== watcherData.commentId ? item : res.data));
						setData(tempData);
					});

				return result;

			default:
				return result;
		}

		result = await commentDataLoader
			.patch(commentDataInput.commentId, fieldName, fieldValue, 'replace')
			.then(rs => {
				const editedComment = rs;
				const tempData = data.map(item =>
					item.CommentId !== commentDataInput.commentId ? item : editedComment
				);
				setData(tempData);
				setEditComment('');
			});
		return result;
	};

	const deleteComment = commentId => {
		setLoading(true);
		commentDataLoader.delete({ id: commentId });
		const tempComments = _.cloneDeep(data);
		const tempData = _.filter(tempComments, elem => {
			if (elem.CommentId !== commentId) {
				return elem;
			}
		});
		setData(tempData);
		setEditComment('');
		setTimeout(() => {
			setLoading(false);
		}, 300);
	};

	useEffect(() => {
		document.addEventListener('keydown', keydownEventHandler, true);

		return () => {
			document.removeEventListener('keydown', keydownEventHandler, true);
		};
	}, []);

	const keydownEventHandler = event => {
		if (event.key === 'Escape') {
			setEditComment('');
		}
	};

	const retrieveCommentAttachments = () => {
		let commentAttachments = [];
		data.forEach(comment => {
			commentAttachments = [
				...commentAttachments,
				...(!comment.IsPrivate && comment.Attachments ? comment.Attachments : []),
			];
		});
		return commentAttachments;
	};

	return !loading && caseData ? (
		<TabPanel>
			<div className="header">
				<div className="left">
					<div className="colored-icon info-icon">
						<ChatOutlined sx={{ fontSize: 20 }} />
					</div>
					<h2>Comments</h2>
				</div>
				<HeaderActionButton active={showFiles} onClick={() => toggleFiles(!showFiles)}>
					<FolderOutlined />
					<p style={{ marginTop: '0px', marginBottom: '0px' }}>Files</p>
				</HeaderActionButton>
			</div>
			<div className="body">
				{showFiles ? (
					<Box
						sx={{
							margin: '10px',
						}}
					>
						<CommentFiles
							isFileView
							filesInput={retrieveCommentAttachments()}
							setEditComment={setEditComment}
							toggleFiles={toggleFiles}
						/>
					</Box>
				) : (
					<DetailComments
						attachments={attachments}
						commentData={commentData}
						data={data}
						deleteComment={deleteComment}
						editComment={editComment}
						progress={progress}
						sendPrivate={sendPrivate}
						setAttachments={setAttachments}
						setCommentData={setCommentData}
						setEditComment={setEditComment}
						setSendPrivate={setSendPrivate}
						submitComment={submitComment}
						updateComment={updateComment}
						users={users}
					/>
				)}
			</div>
		</TabPanel>
	) : (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				paddingBottom: '100px',
				boxSizing: 'border-box',
			}}
		>
			<Typography
				sx={{
					textAlign: 'center',
					fontSie: '16px',
					marginBottom: '8px',
					color: 'black',
				}}
			>
				<CircularProgress
					className="progressBar"
					size={22}
					sx={{
						color: '#03dac5',
						marginRight: '16px',
					}}
				/>
				Loading...
			</Typography>
		</Box>
	);
};

export default Comments;
